// Auth controller

import { AuthAPI } from "@/api/api-auth";
import { WalletInfo } from "@/api/api-wallet";
import { setCookie } from "@/utils/cookie";
import { Request } from "@/utils/request";
import { Timeouts } from "@/utils/timeout";
import { AppEvents } from "./app-events";
import { LocalStorage } from "./local-storage";


export class AuthController {
    public static Status: "UNAUTHORIZED" | "LOGGED_IN" | "USER_NOT_FOUND" | "TFA_REQUIRED" = "UNAUTHORIZED";

    public static UID = "";
    public static AccountType = "";
    public static GlobalAdmin = false;
    public static Permissions = [];
    public static Username = "";
    public static Email = "";
    public static Image = "";

    public static PageToGo = "home";
    public static PageToGoParams = {};

    public static RequiresTwoFactorAuthentication = false;

    public static Session = "";

    public static Wallet: WalletInfo;

    public static TLSValidatorURI = "";

    public static StripeWithdrawFee = 0;
    public static PaypalWithdrawFee = 0;
    public static PaymentMethods = [];
    
    public static Loading = true;

    public static Initialize() {
        AuthController.Session = LocalStorage.Get("x-session-token", "");
        AuthController.CheckAuthStatus();
        AppEvents.AddEventListener("unauthorized", AuthController.ClearSession);
    }

    public static isAuthenticated(): boolean {
        return AuthController.Status === "LOGGED_IN" && AuthController.hasWallet();
    }

    public static isAskingForTwoFactor(): boolean {
        return AuthController.Status === "TFA_REQUIRED";
    }

    public static isAskingForWallet(): boolean {
        return AuthController.Status === "LOGGED_IN" && !AuthController.hasWallet();
    }

    public static hasWallet(): boolean {
        return !!AuthController.Wallet;
    }

    public static hasPermission(perm: string): boolean {
        return AuthController.GlobalAdmin || AuthController.Permissions.includes(perm);
    }

    public static CheckAuthStatus() {
        AuthController.Loading = true;
        AppEvents.Emit("auth-status-loading", true);
        Timeouts.Abort("auth-control-check");
        Request.Pending("auth-control-check", AuthAPI.Context()).onSuccess(response => {
            AuthController.Status = response.status;
            AuthController.UID = response.uid;
            AuthController.AccountType = response.account_type;
            AuthController.GlobalAdmin = response.global_admin;
            AuthController.Permissions = response.permissions || [];
            AuthController.Username = response.username;
            AuthController.Image = response.image;
            AuthController.Wallet = response.wallet || null;
            AuthController.TLSValidatorURI = response.tls_validator_uri;
            AuthController.PaypalWithdrawFee = response.paypal_withdraw_fee;
            AuthController.StripeWithdrawFee = response.stripe_withdraw_fee;
            AuthController.PaymentMethods = response.payment_methods;
            AuthController.Email = response.email;
            AuthController.RequiresTwoFactorAuthentication = response.tfa;
            AuthController.Loading = false;
            setCookie("session_id", AuthController.Session);
            AppEvents.Emit("auth-status-changed");
            AppEvents.Emit("auth-status-loading", false);

        }).onRequestError(err => {
            Request.ErrorHandler()
                .add(401, "TFA_REQUIRED", () => {
                    console.log("AuthController.Status");
                    AuthController.Status = "TFA_REQUIRED";
                    AuthController.UID = "";
                    AuthController.Username = "";
                    AuthController.Wallet = null;
                    AuthController.Loading = false;
                    AppEvents.Emit("auth-status-changed");
                    AppEvents.Emit("auth-status-loading", false);
                })
                .add(401, "*", () => {
                    AuthController.Status = "UNAUTHORIZED";
                    AuthController.UID = "";
                    AuthController.Username = "";
                    AuthController.Wallet = null;
                    AuthController.Loading = false;
                    AppEvents.Emit("auth-status-changed");
                    AppEvents.Emit("auth-status-loading", false);
                })
                .add("*", "*", () => {
                    // Retry
                    Timeouts.Set("auth-control-check", 1500, AuthController.CheckAuthStatus);
                })
                .handle(err);
        }).onUnexpectedError(err => {
            console.error(err);
            // We assume the credentials are invalid
            AuthController.Status = "UNAUTHORIZED";
            AuthController.UID = "";
            AuthController.Username = "";
            AuthController.Wallet = null;
            AuthController.Loading = false;
            AppEvents.Emit("auth-status-changed");
            AppEvents.Emit("auth-status-loading", false);
        });
    }

    public static ClearSession() {
        AuthController.Status = "UNAUTHORIZED";
        AuthController.Session = "";

        LocalStorage.Set("x-session-token", "");

        AuthController.UID = "";
        AuthController.Username = "";
        AuthController.Wallet = null;

        AppEvents.Emit("auth-status-changed");
    }

    public static SetSession(session: string) {
        AuthController.Status = "UNAUTHORIZED";
        AuthController.Session = session;
        AuthController.UID = "";
        AuthController.Username = "";
        AuthController.Wallet = null;
        LocalStorage.Set("x-session-token", session);
        AppEvents.Emit("auth-status-changed");
        AuthController.CheckAuthStatus();
    }

    public static Logout() {
        const currentSession = AuthController.Session;
        Request.Do(AuthAPI.Logout()).onSuccess(() => {
            if (AuthController.Session === currentSession) {
                AuthController.ClearSession();
            }
        }).onRequestError(() => {
            if (AuthController.Session === currentSession) {
                AuthController.ClearSession();
            }
        });
    }
}
