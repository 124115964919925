
import { AppEvents } from "@/control/app-events";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserLink",
  props: {
    id: String,
    name: String
  },
  methods: {
    renderName: function (name) {
      if (!name) {
        return "[" + this.$t("Deleted user") + "]";
      }
      return "@" + name;
    },
    onClick: function () {
      AppEvents.Emit("profile-open", this.id);
    },
  },
});
