
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletAPI } from "@/api/api-wallet";
import { AuthController } from "@/control/auth";
import PasswordStrengthHint from "@/components/utils/PasswordStrengthHint.vue";
import ToggleSwitch from "@/components/utils/ToggleSwitch.vue";

export default defineComponent({
  components: {
    PasswordStrengthHint,
    ToggleSwitch,
  },
  name: "WalletReplaceModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      createWalletPassword: "",
      createWalletPassword2: "",
      createWalletPrivateKey: "",

      confirmation: false,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.createWalletPassword = "";
      this.createWalletPassword2 = "";
      this.createWalletPrivateKey = "";
      this.error = "";
      this.confirmation = false;
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.createWalletPassword) {
        this.error = this.$t("You must specify a password");
        return;
      }

      if (this.createWalletPassword !== this.createWalletPassword2) {
        this.error = this.$t("The passwords do not match");
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(WalletAPI.CreateWallet(this.createWalletPassword, this.createWalletPrivateKey))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AuthController.CheckAuthStatus();
          AppEvents.ShowSnackBar(this.$t("Wallet replaced successfully"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "WEAK_PASSWORD", () => {
              this.error = this.$t("The provided password is too short");
            })
            .add(400, "INVALID_PRIVATE_KEY", () => {
              this.error = this.$t("Invalid private key provided for the wallet");
            })
            .add(400, "PENDING_CONTRACTS", () => {
              this.error = this.$t("You cannot do this while you have pending contracts");
            })
            .add(400, "PENDING_BALANCE", () => {
              this.error = this.$t("You cannot do this while you have positive balance in your account. Withdraw it first.");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("wallet-replace-open", this.$options.openH);
    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("wallet-replace-open", this.$options.openH);
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
