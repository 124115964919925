
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "WalletSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      username: AuthController.Username,
      address: AuthController.Wallet ? AuthController.Wallet.address : "0x",
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    goBack: function () {
      this.close();
      AppEvents.Emit("account-settings-open");
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    onAuthChanged: function () {
      this.username = AuthController.Username;
      this.address = AuthController.Wallet ? AuthController.Wallet.address : "0x";
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    openPasswordChange: function () {
      AppEvents.Emit("wallet-password-change-open");
    },

    openExport: function () {
      AppEvents.Emit("wallet-export-open");
    },

    openWalletReplace: function () {
      AppEvents.Emit("wallet-replace-open");
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    copyWalletAddress: function () {
      navigator.clipboard.writeText(this.address);
      AppEvents.ShowSnackBar(this.$t("Wallet address copied to clipboard!"));
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("wallet-settings-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("wallet-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
