// Onboarding guide

import { escapeHTML } from "@/utils/text";
import { AppEvents } from "./app-events";
import { LocalStorage } from "./local-storage";
import { AppPreferences } from "./app-preferences";

function forceObject(o: any): any {
    if (!o || typeof o !== "object") {
        return Object.create(null);
    }

    return o;
}

function $t(s: string): string {
    return s;
}

export class AdvicesController {

    public static Understood = {};
    public static Advices = [];
    public static Timer = null;

    public static Initialize () {
        if (AppPreferences.AdviceSave) {
            AdvicesController.Understood = this.load();
        }
        AdvicesController.initAdvices();
        AdvicesController.Timer = setInterval(AdvicesController.tick.bind(this), 1000);
    }

    public static load () {
        try {
            return forceObject(LocalStorage.Get("advices-understood", {}));
        } catch (ex) {
            console.error(ex);
            return {};
        }
    }

    public static save () {
        try {
            LocalStorage.Set("advices-understood", AdvicesController.Understood);
        } catch (ex) {
            console.error(ex);
        }
    }

    public static understand (elem: string) {
        AdvicesController.Understood[elem] = true;
        AdvicesController.Advices = AdvicesController.filterAdvices(AdvicesController.Advices);
        AdvicesController.save();
        AdvicesController.tick();
    }

    public static understandAll () {
        AdvicesController.Understood["all"] = true;
        AdvicesController.Advices = AdvicesController.filterAdvices(AdvicesController.Advices);
        AdvicesController.save();
    }

    public static reset () {
        AdvicesController.Understood = {};
        AdvicesController.initAdvices();
        AdvicesController.save();
    }

    public static tick() {
        const windowH = window.innerHeight;
        const windowW = window.innerWidth;

        for (let i = 0; i < AdvicesController.Advices.length; i++) {
            const advice = AdvicesController.Advices[i];
            const elem = document.querySelector(advice.element);
            if (!elem || elem.offsetParent === null) {
                continue; // The element is not in the document
            }
            const elemBounds = elem.getBoundingClientRect();
            const cx = elemBounds.left + (elemBounds.width / 2);
            const cy = elemBounds.top + (elemBounds.height / 2);

            if (cx < 0 || cy < 0 || cx > windowW || cy > windowH) {
                continue; // Element is outside the window
            }

            AppEvents.Emit("help-box-open", advice.element, advice.html || escapeHTML(advice.text || ""), advice.zindex);
            return;
        }
    }

    public static filterAdvices(advices) {
        return advices.filter(function (a) {
            return !AdvicesController.Understood["all"] && !AdvicesController.Understood[a.element];
        }.bind(this));
    }

    public static initAdvices() {
        const advices = []

        advices.push({
            element: "#new-loan-top-bar-button",
            text: $t("Use this button to create a new loan request."),
            zindex: 0,
        });

        advices.push({
            element: "#notifications-top-bar-button",
            text: $t("Use this button to view the latest notifications."),
            zindex: 0,
        });

        advices.push({
            element: "#add-balance-button",
            text: $t("Use this button to add balance."),
            zindex: 0,
        });

        advices.push({
            element: "#withdraw-balance-button",
            text: $t("Use this button to withdraw balance."),
            zindex: 0,
        });

        // Payment methods

        advices.push({
            element: "#add-payment-method-button",
            text: $t("Use this button to add a payment method and withdraw balance."),
            zindex: 0,
        });

        // Contracts 

        advices.push({
            element: "#contracts-side-bar-button",
            text: $t("Use this button to view the status of your contracts."),
            zindex: 0,
        });

        advices.push({
            element: "#contracts-top-menu",
            text: $t("Use this menu to filter contracts by status"),
            zindex: 0,
        });

        advices.push({
            element: "#loan-requests-side-bar-button",
            text: $t("Use this button to explore loan requests."),
            zindex: 0,
        });

        advices.push({
            element: "#my-loan-requests-side-bar-button",
            text: $t("Use this button to view your loan requests."),
            zindex: 0,
        });

        advices.push({
            element: "#my-disputes-side-bar-button",
            text: $t("Use this button to view your disputes."),
            zindex: 0,
        });

        advices.push({
            element: "#about-side-bar-button",
            text: $t("Use this button to view platform information."),
            zindex: 0,
        });

        advices.push({
            element: "#log-out-side-bar-button",
            text: $t("Use this button to log out."),
            zindex: 0,
        });


        AdvicesController.Advices = AdvicesController.filterAdvices(advices);
    }
}
