// Notifications API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";

export interface NotificationDataKYCAccepted {
    type: "kyc_accepted";
}

export interface NotificationDataKYCRejected {
    type: "kyc_rejected";
    reason: string;
}

export interface NotificationDataOfferCreated {
    type: "offer_new";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationDataOfferAccepted {
    type: "offer_accepted";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationDataOfferMessage {
    type: "offer_message";
    reqId: string;
    offerId: string;
    reqTitle: string;
    messageId: string;
    lenderId: string;
    borrowerId: string;
}


export interface NotificationDataOfferContract {
    type: "offer_contract";
    reqId: string;
    offerId: string;
    reqTitle: string;
    contractId: string;
}

export interface NotificationDataOfferContractSignature {
    type: "contract_signed";
    contractId: string;
}

export interface NotificationDataInitialTransfer {
    type: "initial_transfer";
    contractId: string;
}

export interface NotificationDataPeriodPayment {
    type: "period_payment";
    contractId: string;
}

export interface NotificationDataPeriodPaymentCanceled {
    type: "period_payment_canceled";
    contractId: string;
}

export interface NotificationDataRefundPayment {
    type: "refund_payment";
    contractId: string;
}

export interface NotificationDataContractSigned {
    type: "contract_signed";
    contractId: string;
}

export interface NotificationDataContractDeleted {
    type: "contract_deleted";
    contractId: string;
}

export interface NotificationDataRequestClosed {
    type: "request_closed";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationDataOfferClosed {
    type: "offer_closed";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationDataUnknown {
    type: "unknown";
}

export type NotificationData = NotificationDataKYCAccepted | NotificationDataKYCRejected | NotificationDataOfferCreated | NotificationDataOfferAccepted | NotificationDataOfferContract | NotificationDataOfferContractSignature | NotificationDataOfferMessage | NotificationDataRequestClosed | NotificationDataOfferClosed | NotificationDataUnknown | NotificationDataInitialTransfer | NotificationDataPeriodPayment | NotificationDataPeriodPaymentCanceled | NotificationDataRefundPayment | NotificationDataContractSigned | NotificationDataContractDeleted;

export interface PlatformNotification {
    id: string;
    date: number;
    data: NotificationData;
}

export class NotificationsAPI {
    public static GetStatus(): RequestParams<{ unread: number, total: number }> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications"),
        };
    }

    public static List(oldest: number): RequestParams<PlatformNotification[]> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications/list") + GenerateURIQuery({ oldest: oldest }),
        };
    }

    public static Read(timestamp: number): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/notifications/read"),
            json: {
                timestamp: timestamp
            },
        };
    }

    public static Delete(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/notifications/id/" + encodeURIComponent(id)),
        };
    }
}
