// User comment API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { UserInfoMin } from "./api-auth";

export interface UserComments {
    id: string;
    userCommentedId: string;
    userCommentingId: UserInfoMin;
    date: number;
    comment: string;
}

export class UserCommentAPI { 

    public static ListComments(userCommentedId: string, oldest: number): RequestParams<UserComments[]> {
        return {
            method: "GET",
            url: GetAPIURL("/user/comments/" + encodeURIComponent(userCommentedId)) + GenerateURIQuery({oldest: oldest}),
        };
    }

    public static SendComment(userCommentedId: string, comment: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/user/comment/" + encodeURIComponent(userCommentedId)),
            json: {
                userCommentedId: userCommentedId,
                comment: comment
            },
        };
    }
}