// Lending API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { UserInfoMin } from "./api-auth";

export type PeriodType = "day" | "week" | "month" | "3month" | "6month" | "year";

export interface LendingRequestItem {
    id: string;
    user: UserInfoMin;
    date: number;
    closed: boolean;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
    title: string;
    offersCount: number;
    defaultRisk: number;
    sector: string;
    customSector: string;
    repaymentInterval: string;
}

export interface LendingRequestList {
    requests: LendingRequestItem[];
    page: number;
    totalPages: number;
    total: number;
}

export interface PersonalFormBody {
    id: string;
    fullName: string;
    country: string;
    city: string;
    gender: string;
    birthDate: number;
    employmentStatus: string;
    occupation: string;
    customOccupation: string;
    experience: string;
    income: string;
    obligations: string[];
    homeOwnership: string;
}

export interface PersonalForm {
    id: string;
    fullName: string;
    country: string;
    city: string;
    gender: string;
    birthDate: number;
    employmentStatus: string;
    occupation: string;
    customOccupation: string;
    experience: string;
    income: string;
    obligations: string[];
    homeOwnership: string;
}

export interface LendingRequestBody {
    title: string;
    description: string;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
    sector: string;
    customSector: string;
    repaymentInterval: string;
    defaultRisk: number;
}


export interface LendingRequest {
    id: string;
    user: UserInfoMin;
    date: number;
    closed: boolean;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
    sector: string;
    customSector: string;
    repaymentInterval: string;
    title: string;
    offersCount: number;
    description: string;
    editDate: number;
    defaultRisk: number;
    documents: string[];
}

export interface LendingOfferItem {
    id: string;
    user: UserInfoMin;
    request: string;
    date: number;
    closed: boolean;
    accepted: boolean;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
    pendingContract: string;
}

export interface LendingOfferList {
    offers: LendingOfferItem[];
    page: number;
    totalPages: number;
    total: number;
}

export interface LendingOfferCreateBody {
    request: string;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
}

export interface LendingOfferModifyBody {
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
}

export interface LendingOffer {
    id: string;
    user: UserInfoMin;
    request: string;
    date: number;
    closed: boolean;
    accepted: boolean;
    amount: string;
    interest: string;
    periodType: PeriodType;
    periodCount: number;
    pendingContract: string;
    editDate: number;
}

export interface OfferMessage {
    id: string;
    date: number;
    user: UserInfoMin;
    message: string;
    editDate: number;
}

export interface PersonalFormDataResponse {
    personalFormRequestExists: boolean;
}

export class LendingAPI {
    /* Requests */

    public static ListRequests(options: {currency: string, uid?: string, from?: number, to?: number, risk?: string, page: number}): RequestParams<LendingRequestList> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/requests") + GenerateURIQuery(options),
        };
    }

    public static GetRequest(id: string, options: {currency: string}): RequestParams<LendingRequest> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/requests/" + encodeURIComponent(id)) + GenerateURIQuery(options),
        };
    }

    public static CreatePersonalForm(request: PersonalFormBody): RequestParams<PersonalForm> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/personal-form"),
            json: request,
        };
    }

    public static CheckPersonalFormData(): RequestParams<PersonalFormDataResponse> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/personal-form/"),
        };
    }

    public static CreateRequest(request: LendingRequestBody): RequestParams<LendingRequest> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/requests"),
            json: request,
        };
    }

    public static UpdateRequest(id: string, updated: LendingRequestBody): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/requests/" + encodeURIComponent(id)),
            json: updated,
        };
    }

    public static CloseRequest(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/lending/requests/" + encodeURIComponent(id)),
        };
    }

    /* Offers */

    public static ListOffers(options: {currency: string, uid?: string, request?: string, from?: number, to?: number, page: number}): RequestParams<LendingOfferList> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/offers") + GenerateURIQuery(options),
        };
    }

    public static GetOffer(id: string, options: {currency: string}): RequestParams<LendingOffer> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(id)) + GenerateURIQuery(options),
        };
    }

    public static CreateOffer(offer: LendingOfferCreateBody): RequestParams<LendingOffer> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/offers"),
            json: offer,
        };
    }

    public static UpdateOffer(id: string, updated: LendingOfferModifyBody): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(id)),
            json: updated,
        };
    }

    public static AcceptOffer(id: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(id) + "/accept"),
        };
    }

    public static CloseOffer(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(id)),
        };
    }

    /* Messages */

    public static ListMessages(offer: string, oldest?: number): RequestParams<OfferMessage[]> {
        return {
            method: "GET",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(offer) + "/messages") + GenerateURIQuery({oldest: oldest}),
        };
    }

    public static PostMessage(offer: string, message: string): RequestParams<OfferMessage> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/offers/" + encodeURIComponent(offer) + "/messages"),
            json: {
                message: message,
            },
        };
    }

    public static EditMessage(msgId: string, newMessage: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/lending/messages/" + encodeURIComponent(msgId)),
            json: {
                message: newMessage,
            },
        };
    }

    public static DeleteMessage(msgId: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/lending/messages/" + encodeURIComponent(msgId)),
        };
    }
}
