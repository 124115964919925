
import { defineComponent } from "vue";
import { useVModel } from "../../utils/vmodel";

export default defineComponent({
  name: "ToggleSwitch",
  emits: ["update:val"],
  props: {
    val: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    return {
      valState: useVModel(props, "val"),
    };
  },
  data: function () {
    return {
    };
  },
  methods: {
    toggle: function () {
      if (this.disabled) {
        return;
      }
      this.valState = !this.valState;
    },
    keyToggle: function (e) {
      if (e.key === " " || e.key === "Enter") {
        this.toggle();
      }
      e.stopPropagation();
    },
  },
  mounted: function () {
  },
  beforeUnmount: function () {},
});
