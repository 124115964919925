
import { defineComponent } from "vue";

import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { FocusTrap } from "@/utils/focus-trap";
import { useVModel } from "@/utils/vmodel";

export default defineComponent({
  components: {},
  name: "CookiesModal",
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      focusTrap: null as FocusTrap,
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      isDark: AppPreferences.Theme === "dark",
    };
  },
  methods: {
    acceptAllCookies: function () {
      AppPreferences.SetCookiesPreference("all");
      this.close();
    },

    acceptOnlyEssentialCookies: function () {
      AppPreferences.SetCookiesPreference("essential");
      this.close();
    },

    onThemeChanged: function () {
      this.isDark = AppPreferences.Theme === "dark";
    },

    focusLost: function () {
      if (this.display) {
        this.$el.focus();
      }
    },

    close: function () {
      this.displayStatus = false;
    },
  },
  mounted: function () {
    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);

    this.focusTrap = new FocusTrap(this.$el, this.focusLost.bind(this));

    if (this.display) {
      this.focusTrap.activate();
    }
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("theme-changed", this.$options.onThemeChangedH);

    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        if (this.focusTrap) {
          this.focusTrap.activate();
        }
      } else if (this.focusTrap) {
          this.focusTrap.deactivate();
        }
    },
  },
});
