// User rating API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface CurrentUserRating {
    rating: string;
}

export class UserRatingAPI {
    public static GetCurrentUserRating(userEvaluatedId: string): RequestParams<CurrentUserRating> {
        return {
            method: "GET",
            url: GetAPIURL("/user-rating/" + encodeURIComponent(userEvaluatedId)),
        };
    }

    public static SetRating(userEvaluatedId: string, rating: number): RequestParams<{ newAverageRating: number }> {
        return {
            method: "POST",
            url: GetAPIURL("/user-rating/" + encodeURIComponent(userEvaluatedId)),
            json: {
                rating: rating
            },
        };
    }
}